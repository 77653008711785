<template>
  <div
    v-if="businessRef && businessRef.subscription.plan.name === Plan.BASIC"
    class="row justify-content-center align-items-center text-center"
  >
    <div class="limited-user col-12 col-md-3 py-5">
      <img src="@/assets/unavailable-property.svg">
      <h3 class="mt-3">
        Your account access is currently limited.
      </h3>
      <p class="px-5 line-height-1.5">
        Your business is on the Pay-as-you-go plan which does not include multiple team-member access.
        Your account owner ({{ businessRef.billing_email }}) may upgrade your account as needed.
      </p>
      <ElButton
        class="mt-5"
        @click.native="logout()"
      >
        Sign out
      </ElButton>
    </div>
  </div>
</template>

<script>
import { ref, onMounted } from '@vue/composition-api';
import { redirectToSignIn } from '@/router';
import Plan from '@/constants/Plan';

export default {
  setup(props, context) {
    const store = context.root.$store;
    const businessRef = ref(null);
    onMounted(init);

    return {
      businessRef,
      logout,
      Plan,
    };

    async function init() {
      const business = await store.dispatch('Business/get');
      if (business.subscription.plan.name !== Plan.BASIC) {
        context.root.$router.push('/');
        return;
      }
      businessRef.value = business;
    }

    async function logout() {
      await store.dispatch('Auth/logout');
      redirectToSignIn(context);
    }
  },
};
</script>
<style lang="scss" scoped>
.limited-user {
  box-shadow: 0px 4px 100px rgba(0, 0, 0, 0.05);
  min-width: 40vw;
}
</style>
